
import { defineComponent, onMounted, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useHotKeywordStatistics } from '@/composables/api';
import {
  DEFAULT_PAGE_SIZE,
  SortOrder,
  HotKeywordStatisticsSortKey,
  exportHotKeywordStatisticsExcel,
  OrderSortKey
} from '@/services/api';
import { formatLocalTime, formatISOString } from '@/utils/format-time';
import SearchBox from '@/components/keyword-searching/Index.vue';
import { getThousandSeparator } from '@/utils/render';
import download from 'downloadjs';
import dayjs from 'dayjs';
import { useKeyword, usePage, useSort } from '@/composables';

export default defineComponent({
  components: {
    SearchBox
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const { keyword } = useKeyword();
    const { setPageQuery, page, pageSize } = usePage(1, DEFAULT_PAGE_SIZE);

    const yesterdayStart = dayjs().subtract(1, 'day').toISOString();
    const yesterdayEnd = dayjs(`${dayjs().subtract(1, 'day').format('YYYY-MM-DD')} 23:59:59`).toISOString();

    const startDate = ref();
    const endDate = ref();
    const dateRange = ref(null);

    const { sortKey, sortOrder } = useSort<OrderSortKey>();
    const { data, isFetching, isLoading } = useHotKeywordStatistics({
      startDate,
      endDate,
      page,
      pageSize,
      sortKey,
      sort: sortOrder,
      keyword
    });

    const sortChange = ({ prop, order }) => {
      sortKey.value = prop;
      order === 'ascending'
        ? sortOrder.value = SortOrder.ASCENDING
        : sortOrder.value = SortOrder.DESCENDING;

      if (!prop) {
        sortOrder.value = null;
      }
    };

    const handleDateRangeChange = () => {
      if (!dateRange.value) {
        startDate.value = '';
        endDate.value = '';
        return;
      }
      startDate.value = formatISOString(dateRange.value[0]);
      endDate.value = formatISOString(dateRange.value[1]);
    };

    const handleExcelExport = async() => {
      const data = await exportHotKeywordStatisticsExcel({
        query: {
          startDate: startDate.value,
          endDate: endDate.value,
          sortKey: sortKey.value,
          sort: sortOrder.value,
          keyword: keyword.value
        }
      });

      const statisticsStartDate = dayjs(`${startDate.value}`).format('YYYYMMDD');
      const statisticsEndDate = dayjs(`${endDate.value}`).format('YYYYMMDD');

      // 下載匯出檔案
      download(
        data,
       `數據 熱門關鍵字 ${statisticsStartDate}-${statisticsEndDate}.xlsx`,

       'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      );
    };

    onMounted(() => {
      handleDateRangeChange();
    });

    watch([startDate, endDate], ([startDate, endDate]) => {
      router.push({ query: { ...route.query, startDate, endDate } });
    });

    watch(
      () => route.query,
      (query) => {
        startDate.value = query.startDate || yesterdayStart as string;
        endDate.value = query.endDate || yesterdayEnd as string;

        // 避免[null,null], datePicker會顯示當天日期
        if (startDate.value && endDate.value) {
          dateRange.value = [startDate.value, endDate.value];
          return;
        }
        dateRange.value = null;
      },
      { immediate: true }
    );

    return {
      page,
      data,
      sortKey,
      dateRange,
      sortOrder,
      isLoading,
      isFetching,
      HotKeywordStatisticsSortKey,
      keyword,
      sortChange,
      setPageQuery,
      formatLocalTime,
      handleExcelExport,
      getThousandSeparator,
      handleDateRangeChange
    };
  }
});
